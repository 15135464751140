@config "../tailwind.config.js";

@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Cabin');
@import url('https://fonts.googleapis.com/css?family=Mulish');
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

html {
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Cabin', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #F8F8F8;
}

#root {
  height: 100%;
  min-height: 100vh;
  background-color: #F8F8F8;
}

code {
  font-family: 'Nunito', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
